<template>
  <section class="section has-background-white">
    <div class="loading is-loading" v-if="loading">
      Loading...
    </div>

    <div class="notification is-warning has-text-white" v-if="error">
      Book page cannot be retrieved, error is: {{error}}
    </div>

    <div v-if="pageData">
      <div class="container has-text-centered" v-if="pageData">
        <h1 class="title is-size-2 has-text-weight-light">
          {{bookTitleAndSummary(bookname).title}}
        </h1>
        <h2 class="subtitle is-size-4">
          <span  v-if="pageData.title != bookTitleAndSummary(bookname).title">{{pageData.title}}</span>&nbsp;
        </h2>
      </div>
      <div class="columns">
        <div class="column is-one-fifth">
          <book-contents :bookname="bookname" :slug="slug" :key="bookname"></book-contents>
        </div>
        <div class="column">
          <div class="container">
            <rich-text :document="pageData.pageContent"></rich-text>
          </div>
        </div>
      </div>
      <div class="container">
        <book-nav :bookname="bookname" :key="bookname"></book-nav>
      </div>

    </div>
  </section>
</template>

<script>
  //import components
  import BookContents from '../components/BookContents.vue'
  import BookNav from '../components/BookNav.vue'
  import RichText from '@/components/RichText.vue'


  // get some convenience functions e.g. displayDate and convertToHTML
  import misc from '../mixins/misc'
  import {mapState, mapGetters, mapMutations} from 'vuex'


  export default {
    name: 'BookPage',
    mixins: [misc],
    props: {
      // No props, all from router path
    },
    components: {
      BookContents,
      BookNav,
      RichText
    },
    data: function () {
      return {
        loading: false,
        error: null,
        pageData: null, //NB v-if uses this to hide div when no data
        bookname: "", //The name of the book we are looking at
        slug: "", //The page in the book we are looking at
        // SEO fields
        title: 'Product documentation',
        description: 'Detailed technical documentation pages',
        robots: 'index,follow'
      }
    },
    created() {
      //save the route params to pass on to our components as props
      this.bookname = this.$route.params.bookname;
      this.slug = this.$route.params.slug;
      this.getBookPage(this.bookname, this.slug);
    },
    computed: {
      // mix this into the outer object with the object spread operator
      ...mapGetters('books', [
        'bookTitleAndSummary'
      ]),

      // mix this into the outer object with the object spread operator
      ...mapState({
        profile: state => state.profile, //not used currently
        menu: state => state.menu,
        books: state => state.books,
      })
    },
    methods: {

      /**
       * getBookPage - get specific page of a book
       * @param bookname - name of the book (NB not its title, that's from the root page!)
       * @param slug
       */
      getBookPage: function (bookname, slug) {
        this.$log.debug(`{BookPage} getBookPage called for book ${bookname} page ${slug}`);
        this.error   = this.pageData = null;
        this.loading = true;
        const query = {
          'content_type': process.env.VUE_APP_BOOKPAGE_TYPE_ID,
          'fields.book': bookname,
        };
        if (slug == null) {
          // find the front page for the book
          query['fields.frontPage'] = true;
        }
        else {
          query['fields.slug'] = slug;
        }
        this.$contentful
          .getEntries(query)
          .then((res) => {
            if (res.items && res.items.length > 0) {
              this.pageData = res.items[0].fields;
              // this.$log.debug('{BookPage} getEntries result:', JSON.stringify(this.pageData, null, 2));
              // this.pageData.bodyHTML = this.convertToHTML(this.pageData.pageContent);
              this.pageUpdate({book:bookname, slug:slug});

              //Save SEO data if there is any
              let fields = res.items && res.items[0] && res.items[0].fields ?
                  res.items[0].fields :
                  {};
              if (fields.title) {
                this.title = fields.title;
              }
              if (fields.seoDescription) {
                this.description = fields.seoDescription;
              }
              if (fields.seoRobots) {
                this.robots = fields.seoRobots;
              }
            }
            else {
              this.error = 'No items were returned';
            }
            this.loading = false;
          })
          .catch((error) => {
            this.$log.debug('{BookPage} getPage failed with error:', JSON.stringify(error, null, 2));
            this.loading = false;
            this.error   = error;
          })
      },
      ...mapMutations('books',[
        'pageUpdate' // call `this.pageUpdate({book, slug})`
      ]),
    },

    metaInfo() {
      return {
        title: this.title,
        meta: [
          {
            property: 'og:title',
            content: this.title,
            vmid: 'og:title'
          },
          {
            property: 'og:description',
            content: this.description,
            vmid: 'og:description'
          },
          {
            vmid: 'description',
            name: 'description',
            content: this.description
          },
          {
            vmid: 'robots',
            name: 'robots',
            content: this.robots
          }
        ]
      }
    }


  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

  /*@import "~bulma/sass/utilities/mixins";*/
  /*@import "@/scss/_bulmaOverridden";*/

</style>
